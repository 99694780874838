import { authHeader, handleResponse } from 'helpers';
import { config } from './Config';

export const catalogService = {
  fetchTotalRowNumber,
  fetchItems,
  fetchOptimizedItems,
  fetchOptimizedItemsV3,
  fetchOptimizedItemsV4,
  fetchTopSalesItemIdList,
  getCatalogPropertiesV4,
  getCatalogFavoritesV4,
  getCatalogPromotionsV4,
  getTopSalesV4,
  exportCatalogFile,
};

function fetchTotalRowNumber(filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);
  return fetch(`${config.apiUrl}/api/item/count?filter=${urlFilter}`, requestOptions).then(
    handleResponse
  );
}

function fetchItems(filter, offset, limit, getManufacturers = true, orderBy) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const urlFilter = JSON.stringify(filter);
  return fetch(
    `${
      config.apiUrl
    }/api/item?filter=${urlFilter}&offset=${offset}&limit=${limit}&showManufacturers=${getManufacturers}&limitManufacturers=false&orderBy=${
      orderBy || ''
    }`,
    requestOptions
  ).then(handleResponse);
}

function fetchOptimizedItems(filter, offset, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  const groupItems = filter.groupItem.map((item) => `groupId[]=${item}`).join('&');
  const clientId = filter.clientId ? `&clientId=${filter.clientId}` : '';

  return fetch(
    `${config.apiUrl}/api/optimized?platform=${filter.platform}&offset=${offset}&limit=${limit}${clientId}&${groupItems}`,
    requestOptions
  ).then(handleResponse);
}

function fetchOptimizedItemsV3(filter, offset, limit, filterModel, sortModel, keywords) {
  const groupItems = filter.groupItem.map((item) => `groupId[]=${item}`).join('&');
  const clientId = filter.clientId ? filter.clientId : '';
  const catalogControls = JSON.stringify(filterModel);
  const catalogSort = JSON.stringify(sortModel);
  let encodeKeywords = null;
  if (keywords && keywords !== undefined) {
    encodeKeywords = encodeURIComponent(keywords);
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      ssr: 1,
      platform: filter.platform,
      offset,
      limit,
      clientId,
      groupId: groupItems,
      filterModel: catalogControls,
      sortModel: catalogSort,
      keywords: encodeKeywords,
    }),
  };
  return fetch(`${config.apiUrl}/api/optimized`, requestOptions).then(handleResponse);
}

function getCatalogPropertiesV4(filter, items) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      platform: filter.platform,
      items,
    }),
  };
  return fetch(`${config.apiUrl}/api/v2.0/catalog/properties`, requestOptions).then(handleResponse);
}

function fetchOptimizedItemsV4(filter, offset, limit, filterModel, sortModel, keywords) {
  // const groupItems = filter.groupItem.map((item) => `groupId[]=${item}`).join('&');
  const groupItems = filter.groupItem;
  const clientId = filter.clientId ? filter.clientId : '';
  const catalogControls = JSON.stringify(filterModel);
  const catalogSort = JSON.stringify(sortModel);
  let encodeKeywords = null;
  if (keywords && keywords !== undefined) {
    encodeKeywords = keywords;
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      platform: filter.platform,
      offset,
      limit,
      clientId,
      groupId: groupItems,
      filterModel: catalogControls,
      sortModel: catalogSort,
      keywords: encodeKeywords,
    }),
  };
  return fetch(`${config.apiUrl}/api/v2.0/catalog`, requestOptions).then(handleResponse);
}

function getTopSalesV4(filter, offset, limit, filterModel, sortModel, keywords) {
  // const groupItems = filter.groupItem.map((item) => `groupId[]=${item}`).join('&');
  const groupItems = filter.groupItem;
  const clientId = filter.clientId ? filter.clientId : '';
  const catalogControls = JSON.stringify(filterModel);
  const catalogSort = JSON.stringify(sortModel);
  let encodeKeywords = null;
  if (keywords && keywords !== undefined) {
    encodeKeywords = encodeURIComponent(keywords);
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      platform: filter.platform,
      offset,
      limit,
      clientId,
      groupId: groupItems,
      filterModel: catalogControls,
      sortModel: catalogSort,
      keywords: encodeKeywords,
    }),
  };
  return fetch(`${config.apiUrl}/api/v2.0/catalog/topsales`, requestOptions).then(handleResponse);
}

function getCatalogFavoritesV4(filter, offset, limit, filterModel, sortModel, keywords) {
  // const groupItems = filter.groupItem.map((item) => `groupId[]=${item}`).join('&');
  const groupItems = filter.groupItem;
  const clientId = filter.clientId ? filter.clientId : '';
  const catalogControls = JSON.stringify(filterModel);
  const catalogSort = JSON.stringify(sortModel);
  let encodeKeywords = null;
  if (keywords && keywords !== undefined) {
    encodeKeywords = encodeURIComponent(keywords);
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      platform: filter.platform,
      offset,
      limit,
      clientId,
      groupId: groupItems,
      filterModel: catalogControls,
      sortModel: catalogSort,
      keywords: encodeKeywords,
    }),
  };
  return fetch(`${config.apiUrl}/api/v2.0/catalog/favorites`, requestOptions).then(handleResponse);
}

function getCatalogPromotionsV4(filter, offset, limit, filterModel, sortModel, keywords) {
  // const groupItems = filter.groupItem.map((item) => `groupId[]=${item}`).join('&');
  const groupItems = filter.groupItem;
  const clientId = filter.clientId ? filter.clientId : '';
  const catalogControls = JSON.stringify(filterModel);
  const catalogSort = JSON.stringify(sortModel);
  let encodeKeywords = null;
  if (keywords && keywords !== undefined) {
    encodeKeywords = encodeURIComponent(keywords);
  }
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify({
      platform: filter.platform,
      offset,
      limit,
      clientId,
      groupId: groupItems,
      filterModel: catalogControls,
      sortModel: catalogSort,
      keywords: encodeKeywords,
    }),
  };
  return fetch(`${config.apiUrl}/api/v2.0/catalog/promotions`, requestOptions).then(handleResponse);
}

function fetchTopSalesItemIdList(platformId, startDate, endDate, limit) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };

  return fetch(
    `${config.apiUrl}/api/optimized/top_sales_items?platform=${platformId}&startDate=${startDate}&endDate=${endDate}&limit=${limit}`,
    requestOptions
  ).then(handleResponse);
}

function exportCatalogFile(platformId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    mode: 'cors',
  };
  return fetch(
    `${config.apiUrl}/api/optimized/download?platform=${platformId}`,
    requestOptions
  ).then((response) => response.blob());
}
