export const catalogConstants = {
  FETCH_COUNT_REQUEST: 'CATALOG_COUNT_FETCH_REQUEST',
  FETCH_COUNT_SUCCESS: 'CATALOG_COUNT_FETCH_SUCCESS',
  FETCH_COUNT_FAILURE: 'CATALOG_COUNT_FETCH_FAILURE',
  FETCH_REQUEST: 'CATALOG_FETCH_REQUEST',
  FETCH_SUCCESS: 'CATALOG_FETCH_SUCCESS',
  FETCH_FAILURE: 'CATALOG_FETCH_FAILURE',
  TEMPERATURE_DRY: 'SEC',
  TEMPERATURE_FRESH: 'POSITIF',
  TEMPERATURE_FROZEN: 'FROID',
  UPDATE_FAVORITE: 'CATALOG_UPDATE_FAVORITE',

  FETCH_TOP_SALES_LIST_REQUEST: 'CATALOG_FETCH_TOP_SALES_LIST_REQUEST',
  FETCH_TOP_SALES_LIST_SUCCESS: 'CATALOG_FETCH_TOP_SALES_LIST_SUCCESS',
  FETCH_TOP_SALES_LIST_FAILURE: 'CATALOG_FETCH_TOP_SALES_LIST_FAILURE',

  EXPORT_CATALOG_FILE_REQUEST: 'CATALOG_EXPORT_CATALOG_FILE_REQUEST',
  EXPORT_CATALOG_FILE_SUCCESS: 'CATALOG_EXPORT_CATALOG_FILE_SUCCESS',
  EXPORT_CATALOG_FILE_FAILURE: 'CATALOG_EXPORT_CATALOG_FILE_FAILURE',
};
