import { catalogService } from 'services';
import { saveFile } from 'helpers';

// Importing catalogConstants from the absolute path (`constants`) bugs.
import { catalogConstants, tableConstants, productConstants } from '../constants';
import { manufacturerActions } from './Manufacturer';

const { DIRECTION_REPLACE } = tableConstants;

export const catalogActions = {
  fetchTotalRowsNumber,
  fetchItems,
  toggleProductIsFavorite,
  fetchTopSalesItemIdList,
  exportCatalogFile,
};

function fetchTotalRowsNumber(filter) {
  return (dispatch) => {
    dispatch(request());

    catalogService.fetchTotalRowNumber(filter).then(
      ({ numberDataAvailable }) => {
        dispatch(success({ numberDataAvailable }));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return {
      type: catalogConstants.FETCH_COUNT_REQUEST,
      filter,
    };
  }
  function success({ numberDataAvailable }) {
    return {
      type: catalogConstants.FETCH_COUNT_SUCCESS,
      numberDataAvailable: Number(numberDataAvailable),
      filter,
    };
  }
  function failure(error) {
    return {
      type: catalogConstants.FETCH_COUNT_FAILURE,
      error,
      filter,
    };
  }
}

function fetchItems(
  filter,
  offset,
  limit,
  direction = DIRECTION_REPLACE,
  getManufacturers = true,
  orderBy
) {
  return (dispatch) => {
    dispatch(request());
    if (getManufacturers) {
      dispatch(manufacturerActions.fetchRequest());
    }
    catalogService.fetchItems(filter, offset, limit, getManufacturers, orderBy).then(
      (catalogResult) => {
        const items = !getManufacturers ? catalogResult : catalogResult.items;
        dispatch(success({ items }));
        if (getManufacturers) {
          dispatch(
            manufacturerActions.fetchSuccess({
              manufacturers: catalogResult.manufacturers,
            })
          );
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        if (getManufacturers) {
          dispatch(manufacturerActions.fetchError());
        }
      }
    );
  };
  function request() {
    return {
      type: catalogConstants.FETCH_REQUEST,
      filter,
    };
  }
  function success({ items }) {
    return {
      type: catalogConstants.FETCH_SUCCESS,
      items,
      filter,
      direction,
      orderBy,
    };
  }
  function failure(error) {
    return {
      type: catalogConstants.FETCH_FAILURE,
      error,
      filter,
    };
  }
}

function toggleProductIsFavorite(productId, filter) {
  return (dispatch) => {
    dispatch({
      type: catalogConstants.UPDATE_FAVORITE,
      productId,
      filter,
    });
  };
}

function fetchTopSalesItemIdList(platformId, startDate, endDate, limit) {
  return (dispatch) => {
    dispatch(request());

    catalogService.fetchTopSalesItemIdList(platformId, startDate, endDate, limit).then(
      (topSales) => {
        dispatch(success(topSales));
      },
      (error) => {
        dispatch(failure(error.toString));
      }
    );
    function request() {
      return { type: catalogConstants.FETCH_TOP_SALES_LIST_REQUEST };
    }
    function success(topSalesItemIdList) {
      return { type: catalogConstants.FETCH_TOP_SALES_LIST_SUCCESS, topSalesItemIdList };
    }
    function failure(error) {
      return { type: catalogConstants.FETCH_TOP_SALES_LIST_FAILURE, error };
    }
  };
}

function exportCatalogFile(platformId) {
  return (dispatch) => {
    dispatch(request());
    catalogService.exportCatalogFile(platformId).then(
      (orderFormBlob) => {
        dispatch(success());
        const date = new Date();
        const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        const fileName = `BDC-${formattedDate}.xlsx`;
        saveFile(fileName, orderFormBlob);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: catalogConstants.EXPORT_CATALOG_FILE_REQUEST };
  }
  function success() {
    return { type: catalogConstants.EXPORT_CATALOG_FILE_SUCCESS };
  }
  function failure(error) {
    return { type: catalogConstants.EXPORT_CATALOG_FILE_FAILURE, error };
  }
}
